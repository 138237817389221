import React from "react";
import CreateQuotation from "./views/CreateQuotation";
import Company from "./views/Company";
import GetQuotation from "./views/GetQuotation";
import Contact from "./views/Contact";
import PriceList from "./views/PriceList";
import Admin from "./views/Admin";
import GetContact from "./views/GetContacts";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import StockCheck from "./views/StockCheck";
import DescriptionIcon from "@mui/icons-material/Description";

const routes = [
  {
    path: "/admin",
    name: "Admin",
    icon: <AdminPanelSettingsOutlinedIcon />,
    component: Admin,
  },
  // {
  //   path: "/quotation-admin",
  //   name: "Quotation Admin",
  //   icon: <AddchartOutlinedIcon />,
  //   component: QuotationAdmin,
  // },
  {
    path: "/company",
    name: "Company",
    icon: <DomainAddOutlinedIcon />,
    component: Company,
  },
  {
    path: "/contact-person",
    name: "Contact Person",
    icon: <BadgeOutlinedIcon />,
    component: Contact,
  },
  {
    path: "/contact-person/get",
    name: "Company Data",
    icon: <GroupsOutlinedIcon />,
    component: GetContact,
  },
  // {
  //   path: "/price-list",
  //   name: "Price List",
  //   icon: <DocumentScannerOutlinedIcon />,
  //   component: PriceList,
  // },
  {
    path: "/stock-check",
    name: "Stock",
    icon: <InventoryIcon />,
    component: StockCheck,
  },
  {
    path: "/quotation/create",
    name: "Create Quotation",
    icon: <RequestQuoteOutlinedIcon />,
    component: CreateQuotation,
  },
  {
    path: "/quotation/get",
    name: "Quotation Report",
    icon: <DescriptionIcon />,
    component: GetQuotation,
  },
];

export default routes;
