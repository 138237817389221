import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  IconButton,
  Popover,
  Typography,
  Box,
  Grid,
  Modal,
} from "@mui/material";
import { styles } from "../styles";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import LineItemForm from "./LineItemForm";
import DeleteForm from "./DeleteForm";
import { useEffect } from "react";
import {
  CloudUpload,
  Delete,
  Edit,
  MoreVert,
  Pageview,
  PageviewOutlined,
} from "@mui/icons-material";
import useClasses from "../useClasses";
import { StyledGreenButton, StyledOrangeButton } from "./StyledButtons";
import { StyledTableCell } from "./StyledTableComponents";
import Cookies from "js-cookie";
import { StyledHeader } from "./StyledHeader";
import { API_GATEWAY_URL } from "../utils";
import axios from "axios";

export default function BasicTable(props) {
  const { items, populateItems, handleLineItemImport } = props;
  const classes = useClasses(styles);
  const [lineItems, setLineItems] = useState(items);
  const [modal, setModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [newItem, setNewItem] = useState({
    cpn: "",
    mpn: "",
    description: "",
    annual_usage: "",
    project: "",
    build_site: "",
    manufacturer: "",
    spq: "",
    packaging: "",
    target_price: "",
    supplier_price: "",
    updates: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectLineItem, setSelectLineItem] = useState({});
  const [type, setType] = useState("");
  const [editIndex, setEditIndex] = useState(0);

  // history modal
  const [historyPage, setHistoryPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [historyRowsPerPage, setHistoryRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setLineItems(items);
  }, [items]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleHistoryChangePage = (event, newPage) => {
    setHistoryPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleHistoryChangeRowsPerPage = (event) => {
    setHistoryRowsPerPage(parseInt(event.target.value, 10));
    setHistoryPage(1);
  };

  const openEditModal = () => {
    setNewItem(selectLineItem);
    setModal(true);
  };

  const openHistoryModal = (row) => {
    setSelectLineItem(row);
    setNewItem(row);
    // perform history search here
    axios
      .get(`${API_GATEWAY_URL}/quotation/paginate`, {
        params: {
          creator: Cookies.get("user"),
          email: Cookies.get("email"),
          mpn: row.mpn,
          page: historyPage ? historyPage : 1,
          page_size: 10,

          // stub search values
          quotation_id: "",
          company: "",
          cpn: "",
          type: "",
          sales_person: "",
        },
      })
      .then((response) => {
        let items = [];
        let line_items = response.data.line_items;
        console.log(line_items);
        setTotalCount(response.data.total);
        line_items.sort((a, b) => {
          if (a.quotation_id === b.quotation_id) {
            return a.line_number - b.line_number;
          }

          return (
            parseInt(b.quotation_id.split("EQ-")[1]) -
            parseInt(a.quotation_id.split("EQ-")[1])
          );
        });
        for (let lineItem of line_items) {
          let update = lineItem.last_update;
          if (update != "") {
            update = update.split(" ")[1].toUpperCase();
          }
          items.push({
            cpn: lineItem.cpn,
            project: lineItem.project,
            moq: lineItem.moq,
            c_price: lineItem.unit_price,
            s_price: lineItem.supplier_price,
            update: lineItem.updates,
            company: lineItem.company,
            status: lineItem.status,
            quotation_id: lineItem.quotation_id,
            last_update: lineItem.last_update,
          });
        }
        setSearchResults(items);
        setHistoryPage(historyPage - 1);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        // setOpen(false);
      });
    setHistoryModal(true);
  };

  const closeHistoryModal = () => {
    resetNewItem();
    setSearchResults([]);
    setHistoryPage(1);
    setHistoryModal(false);
  };

  const resetNewItem = () => {
    setNewItem({
      cpn: "",
      mpn: "",
      manufacturer: "",
      description: "",
      annual_usage: "",
      project: "",
      build_site: "",
      spq: "",
      packaging: "",
      moq: "",
      unit_price: "",
      lead_time: "",
      cancellation: "",
      target_price: "",
      supplier_price: "",
      updates: "",
    });
  };

  const closeModal = () => {
    resetNewItem();
    setModal(false);
  };

  const addLineItem = (newLineItems) => {
    let newItemList = [...items, ...newLineItems];
    populateItems(newItemList);
    closeModal();
  };

  const updateLineItem = (newLineItem) => {
    const updated = JSON.parse(JSON.stringify(items));
    updated[editIndex] = newLineItem;
    populateItems(updated);
    closeModal();
  };

  const closeDeleteLineItem = () => {
    setDeleteModal({});
    setDeleteModal(!deleteModal);
  };

  const removeLineItem = (item) => {
    const updated = items.filter((el) => el !== item);
    populateItems(updated);
    setSelectLineItem({});
    setDeleteModal(false);
  };

  const handleMore = (e, row, index) => {
    setAnchorEl(e.currentTarget);
    setSelectLineItem(row);
    setEditIndex(page * rowsPerPage + index);
    setNewItem(row);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;
  const displayedData = items.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const moreOpen = Boolean(anchorEl);
  const moreId = moreOpen ? "simple-popover" : undefined;

  return (
    <Box>
      <Grid
        container
        xs={12}
        lg={12}
        sx={{
          width: "100%",
          marginTop: "0.5em",
          marginBottom: "1em",
        }}
      >
        <Grid container item xs={12} lg={6}></Grid>
        <Grid container item xs={12} lg={6} spacing={2}>
          <Grid item xs={12} lg={6}>
            <StyledGreenButton
              onClick={() => {
                setType("create");
                setModal(!modal);
              }}
              variant="contained"
              startIcon={<AddIcon style={{ fontSize: "14px" }} />}
              style={{ boxShadow: "none" }}
            >
              Line Item
            </StyledGreenButton>
          </Grid>
          <Grid item xs={12} lg={6}>
            <input
              type="file"
              hidden
              id="raised-button-file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleLineItemImport}
            />
            <label htmlFor="raised-button-file">
              <StyledOrangeButton
                variant="contained"
                component="span"
                startIcon={<CloudUpload style={{ fontSize: "14px" }} />}
                style={{ boxShadow: "none" }}
              >
                Import Line Items
              </StyledOrangeButton>
            </label>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer className={classes.table_container}>
        <Table className={classes.table} size="medium">
          <TableHead>
            <TableRow className={classes.table_row_header}>
              <TableCell className={classes.table_cell_header} align="left">
                Line
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                CPN
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                MPN
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Manufacturer
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Usage
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Project
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Build Site
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                SPQ
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Packaging
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                MOQ
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                C.Price
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                S.Price
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                LT
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                CXL
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Target
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Description
              </TableCell>
              <TableCell className={classes.table_cell_header} align="left">
                Update
              </TableCell>
              {Cookies.get("role", null) == "ADMIN" && (
                <TableCell className={classes.table_cell_header} align="left">
                  Profit
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((row, index) => {
              row.index = index;
              return (
                <TableRow
                  key={index.toString()}
                  sx={{
                    backgroundColor: "#fff",
                    border: "#f3f6f8 dotted 2px",
                  }}
                >
                  <StyledTableCell
                    style={{ paddingLeft: "20px" }}
                    className={classes.table_cell}
                  >
                    {page * rowsPerPage + index + 1}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.cpn}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.mpn}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.manufacturer}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.annual_usage}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.project}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.build_site}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.spq}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.packaging}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.moq}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.unit_price}
                    {!isNaN(row.unit_price) &&
                    !isNaN(row.supplier_price) &&
                    parseFloat(row.unit_price) <
                      0.945 * parseFloat(row.supplier_price)
                      ? " ⚠️"
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.supplier_price}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.lead_time}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.cancellation}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.target_price}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.description}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    {row.updates}
                  </StyledTableCell>
                  {Cookies.get("role", null) == "ADMIN" && (
                    <StyledTableCell
                      className={classes.table_cell}
                      align="left"
                    >
                      {row.unit_price && row.supplier_price && row.moq
                        ? (
                            (parseFloat(row.unit_price) -
                              parseFloat(row.supplier_price)) *
                            parseFloat(row.moq)
                          ).toFixed(2)
                        : ""}
                    </StyledTableCell>
                  )}
                  <StyledTableCell className={classes.table_cell} align="left">
                    {Cookies.get("role", null) == "ADMIN" && (
                      <Box>
                        <Button
                          onClick={(e) => {
                            openHistoryModal(row);
                          }}
                          variant="text"
                          className={classes.popover_button}
                          startIcon={<PageviewOutlined />}
                          style={{
                            color: "#e08d35",
                            justifyContent: "center",
                          }}
                        >
                          <Typography className={classes.popover_text}>
                            History
                          </Typography>
                        </Button>
                        <div className={classes.popover_divider} />
                      </Box>
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={classes.table_cell} align="left">
                    <div className={classes.table_cell_icon_wrapper}>
                      <IconButton
                        onClick={(e) => {
                          handleMore(e, row, index);
                        }}
                        aria-label="edit"
                        size="small"
                        className={classes.more_vert}
                      >
                        <MoreVert fontSize="inherit" />
                      </IconButton>
                      <Popover
                        id={moreId}
                        open={moreOpen}
                        anchorEl={anchorEl}
                        onClose={handleMoreClose}
                        className={classes.popover_container}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div className={classes.popover}>
                          <Button
                            onClick={() => {
                              handleMoreClose();
                              setType("update");
                              openEditModal();
                            }}
                            variant="text"
                            className={classes.popover_button}
                            startIcon={<Edit />}
                            sx={{
                              justifyContent: "flex-start",
                              marginLeft: "1em",
                            }}
                          >
                            <Typography className={classes.popover_text}>
                              Edit
                            </Typography>
                          </Button>
                          <div className={classes.popover_divider} />
                          <Button
                            onClick={() => {
                              handleMoreClose();
                              setDeleteModal(true);
                            }}
                            style={{
                              color: "#ff5630",
                              justifyContent: "flex-start",
                              marginLeft: "1em",
                            }}
                            variant="text"
                            className={classes.popover_button}
                            startIcon={<Delete />}
                          >
                            <Typography className={classes.popover_text}>
                              Delete
                            </Typography>
                          </Button>
                        </div>
                      </Popover>
                    </div>
                  </StyledTableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 55.5 * emptyRows }}>
                <StyledTableCell style={{ border: "none", width: "300px" }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <LineItemForm
        num={items.length + 1}
        modal={modal}
        closeModal={closeModal}
        newItem={newItem}
        addLineItem={addLineItem}
        updateLineItem={updateLineItem}
        type={type}
      />
      <DeleteForm
        confirm={removeLineItem}
        cancel={closeDeleteLineItem}
        open={deleteModal}
        item={selectLineItem}
        name="Line item"
      />
      <Modal open={historyModal} onClose={closeHistoryModal}>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "70%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <StyledHeader>{newItem.mpn}</StyledHeader>
          <Box
            container
            sx={{
              margin: "1em 0em",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "50%",
              alignItems: "flex-start",
            }}
          >
            <TableContainer
              sx={{
                boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
                borderRadius: "8px",
                overflowX: "auto",
              }}
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableRowHeader}>
                    {/* Customer  CPN   Project  MOQ       Price        Status       AccountManager   Update */}
                    <TableCell style={{ paddingLeft: "30px" }}>
                      Company
                    </TableCell>
                    <TableCell>Quotation ID</TableCell>
                    <TableCell>CPN</TableCell>
                    <TableCell>MOQ</TableCell>
                    <TableCell>C.Price</TableCell>
                    <TableCell>S.Price</TableCell>
                    <TableCell>Update</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell style={{ paddingRight: "30px" }}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResults.map((result, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: "#fff",
                          border: "#f3f6f8 dotted 2px",
                        }}
                      >
                        <StyledTableCell
                          style={{ paddingLeft: "30px" }}
                          align="left"
                        >
                          {result.company}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.quotation_id}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.cpn}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.moq}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.c_price}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.s_price}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.update}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.project}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {result.status}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ paddingRight: "30px" }}
                          align="left"
                        >
                          {result.last_update}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={historyRowsPerPage}
              page={historyPage}
              onPageChange={handleHistoryChangePage}
              onRowsPerPageChange={handleHistoryChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
