import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Sidebar from "./components/Sidebar.js";
import Drawer from "@mui/material/Drawer";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import CreateQuotation from "./views/CreateQuotation.js";
import GetQuotation from "./views/GetQuotation.js";
import Contact from "./views/Contact.js";
import Company from "./views/Company.js";
import PriceList from "./views/PriceList.js";
import Login from "./views/Login.js";
import {
  CancelOutlined,
  ChevronLeft,
  ChevronRight,
  Menu,
  SaveOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Toolbar,
} from "@mui/material";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "./assets/logo_slim.png"; // Adjust the path to your image file
import Admin from "./views/Admin.js";
import GetContact from "./views/GetContacts.js";
import MuiAppBar from "@mui/material/AppBar";
import StockCheck from "./views/StockCheck.js";
import QuotationAdmin from "./views/QuotationAdmin.js";
import FaceIcon from "@mui/icons-material/Face";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { StyledHeader } from "./components/StyledHeader.js";
import {
  StyledGreenButton,
  StyledRedButton,
} from "./components/StyledButtons.js";
import { API_GATEWAY_URL } from "./utils.js";
import axios from "axios";
import { StyledTextField } from "./components/StyledTextField.js";
import Notification from "./components/Notification.js";
import StockList from "./views/StockList.js";
import StaffManagement from "./views/StaffManagement.js";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    // flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    width: "100%",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#fff",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Default props to be applied to all TextFields
          fontSize: "1em",
          fontFamily: "AirbnbCereal-Book",
          transitionDuration: "0.6s",
          borderRadius: "5px",
          backgroundColor: "#fff",
          "&:hover fieldset": {
            borderColor: "#212b36",
            transitionDuration: "0.6s",
          },
          "&:focus": {
            borderColor: "#212b36",
          },
          variant: "outlined",
          InputProps: {
            // For example, apply a style to all input elements
            style: {
              color: "#212b36",
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
            },
          },
          InputLabelProps: {
            // For example, apply a style to all input elements
            style: {
              color: "#212b36",
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#212b36",
          fontFamily: "AirbnbCereal-Book",
          fontSize: "1em",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          color: "#212b36",
          fontFamily: "AirbnbCereal-Medium",
          fontSize: "0.95em",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#212b36",
          fontFamily: "AirbnbCereal-Medium",
          fontSize: "0.95em",
          padding: "12px 10px",
          whiteSpace: "nowrap",
        },
      },
    },
  },
});

function App() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [annualUsage, setAnnualUsage] = useState("");
  const [backdrop, setBackdrop] = useState(false);

  const handleSnackBar = (sev, msg) => {
    setSeverity(sev);
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    Cookies.remove("wiselink_session");
    Cookies.remove("role");
    Cookies.remove("email");
    Cookies.remove("password");
    Cookies.remove("user");
    Cookies.remove("creator");
    navigate("/");
  };

  const handleOpenAccountModal = () => {
    setEmail(Cookies.get("email", ""));
    setPassword(Cookies.get("password", ""));
    setOpenAccountModal(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const updatePassword = () => {
    if (password.replace(" ", "").length != password.length) {
      handleSnackBar(
        "warning",
        "Ensure there is no whitespace in your password"
      );
      return;
    }
    setBackdrop(true);
    axios
      .post(`${API_GATEWAY_URL}/account/change-password`, {
        email,
        password,
      })
      .then((res) => {
        if (res.status == 200) {
          handleSnackBar("success", res.data);
        } else {
          handleSnackBar("error", res.data);
        }
      })
      .catch((error) => {
        handleSnackBar("error", "Error updating password, try again later.");
      })
      .finally(() => {
        setBackdrop(false);
      });
    Cookies.set("password", password);
  };

  const handleCloseBackdrop = () => {
    setBackdrop(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
        <CssBaseline />
        {useLocation().pathname != "/" && (
          <AppBar open={open}>
            <Toolbar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      mr: 2,
                      ...(open && { display: "none" }),
                      color: "#686868",
                    }}
                  >
                    <Menu />
                  </IconButton>
                  <img src={Logo} alt="Description" width={"150px"} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleOpenAccountModal}
                    startIcon={<FaceIcon />}
                    sx={{
                      fontFamily: "AirbnbCereal-Medium",
                      color: "#2f3945",
                      borderColor: "#2f3945",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "5px",
                      padding: "5px 20px",
                      fontSize: "0.8em",
                      "&:hover": {
                        backgroundColor: "#2f3945",
                        color: "#fff",
                      },
                      marginRight: "1em",
                    }}
                  >
                    Account
                  </Button>
                  <Button
                    onClick={handleLogout}
                    sx={{
                      fontFamily: "AirbnbCereal-Medium",
                      color: "#dc831c",
                      borderColor: "#dc831c",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "5px",
                      padding: "5px 20px",
                      fontSize: "0.8em",
                      "&:hover": {
                        backgroundColor: "#dc831c",
                        color: "#fff",
                      },
                    }}
                    startIcon={<MeetingRoomIcon />}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#f3f6f8",
            },
            width: drawerWidth,
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeft style={{ color: "#686868" }} />
              ) : (
                <ChevronRight />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/quotation-admin" element={<QuotationAdmin />} />
            <Route path="/staff-management" element={<StaffManagement />} />
            <Route exact path="/company" element={<Company />} />
            <Route exact path="/contact-person" element={<Contact />} />
            <Route exact path="/contact-person/get" element={<GetContact />} />
            <Route path="/price-list" element={<PriceList />} />
            <Route path="/stock-list" element={<StockList />} />
            <Route path="/stock-check" element={<StockCheck />} />
            <Route path="/quotation/create" element={<CreateQuotation />} />
            <Route path="/quotation/get" element={<GetQuotation />} />
          </Routes>
        </Main>
      </Box>
      <Modal open={openAccountModal} onClose={() => setOpenAccountModal(false)}>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "50%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <Box>
            <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
              Account
            </StyledHeader>
            <StyledTextField
              disabled
              label="Email"
              variant="outlined"
              value={email}
              style={{ flex: 1, width: "100%", marginTop: "1em" }}
            />
            <StyledTextField
              label="Password"
              variant="outlined"
              value={password}
              // type="password"
              onChange={handlePasswordChange}
              style={{ flex: 1, width: "100%", marginTop: "2em" }}
            />
            <Grid container spacing={2} lg={12}>
              <Grid item lg={6}>
                <StyledGreenButton
                  onClick={updatePassword}
                  style={{ marginTop: "2em" }}
                  startIcon={<SaveOutlined style={{ fontSize: "14px" }} />}
                >
                  Update Password
                </StyledGreenButton>
              </Grid>
              <Grid item lg={6}>
                <StyledRedButton
                  onClick={() => {
                    setOpenAccountModal(false);
                  }}
                  style={{ marginTop: "2em" }}
                  startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
                >
                  Close
                </StyledRedButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Notification
        close={handleCloseSnackbar}
        open={openSnackbar}
        message={message}
        severity={severity}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 1400 }}
        open={backdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}

export default App;
